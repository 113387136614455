import { createStore as reduxCreateStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducer = (state, action) => {
  if (action.type === 'SET_USER') {
    return Object.assign({}, state, { user: action.user });
  }

  if (action.type === 'SET_MODULES') {
    return Object.assign({}, state, { modules: action.modules });
  }

  if (action.type === 'SET_CALLBACK_LINK') {
    return Object.assign({}, state, { callbackLink: action.callbackLink });
  }

  return state;
};

const initialState = {
  user: {
    first: '',
    last: '',
    email: '',
  },
  callbackLink: '/',
};

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

// const createStore = () => reduxCreateStore(reducer, initialState)

const createStore = () => {
  // production
  const store = reduxCreateStore(persistedReducer, initialState);
  // dev
  /* let store = reduxCreateStore(persistedReducer, initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) */
  const persistor = persistStore(store);
  return { persistor, store };
};

export default createStore;
